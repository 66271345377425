<template>
    <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="validate">
        <v-container grid-list-xl>
            <v-layout wrap>
                <v-flex xs12 sm5>
                    <v-switch v-model="item.enable" :label="$t('Habilitado')" />
                    <v-switch v-model="item.makeExternal" :label="$t('Feito pelo laboratório de apoio')" />
                </v-flex>
                <v-flex xs12 sm7>
                    <v-alert icon="warning" prominent text dense type="warning">
                        <b>"Nome completo"</b> será utilizado em todos os pontos do sistema
                        <br />
                        <b>"Nome para Impressão"</b> será utilizado somente na geração do laudo final para o cliente.
                    </v-alert>
                </v-flex>

                <v-flex xs12>
                    <v-text-field :label="$t('Nome Completo')" v-model="item.name" :rules="[rules.required, rules.min(5)]" />
                    <v-text-field :label="$t('Nome para Impressão')" v-model="item.printName" :rules="[rules.required, rules.min(5)]" />
                </v-flex>
                <v-flex xs12 sm6>
                    <v-text-field
                        v-model="item.price"
                        :rules="[rules.money]"
                        :label="$t('Preço')"
                        v-mask="['#.##', '##.##', '###.##', '####.##', '#####.##']"
                        return-masked-value
                        prefix="R$"
                        type="number"
                        required
                    />
                </v-flex>
                <v-flex xs12 sm6 />

                <v-flex xs12 sm6> <v-textarea auto-grow style="font-family: 'courier'" :label="$t('Método')" v-model="item.method" class="my-0 py-0" /> </v-flex>
                <v-flex xs12 sm6> <v-textarea auto-grow style="font-family: 'courier'" :label="$t('Material')" v-model="item.material" class="my-0 py-0" /> </v-flex>

                <v-flex xs12> <v-textarea auto-grow style="font-family: 'courier'" :label="$t('Notas')" v-model="item.notes" class="my-0 py-0" /> </v-flex>

                <v-flex xs12 sm9>
                    <v-textarea auto-grow style="font-family: 'courier'" :label="$t('Observações')" v-model="item.observation" :rules="[needObservation]" class="my-0 py-0" />
                </v-flex>
                <v-flex xs12 sm3> <v-switch v-model="item.observationImportant" :label="$t('Observação é importante?')" /> </v-flex>

                <v-flex xs12>
                    <v-card color="primary" class="elevation-12">
                        <v-card-title class="text-center justify-center py-6">
                            <h1 class="font-weight-bold text-h5 white--text">Mapa de trabalho</h1>
                        </v-card-title>
                        <v-tabs v-model="tab">
                            <v-tabs-slider color="primary"></v-tabs-slider>
                            <v-tab v-for="i in item.plans" :key="i">
                                {{ i.title }}
                            </v-tab>
                            <v-btn color="success" class="align-self-center ml-12" small @click="addGroup">
                                Adicionar Grupo
                                <v-icon right small> add_circle </v-icon>
                            </v-btn>
                        </v-tabs>

                        <v-tabs-items v-model="tab">
                            <v-tab-item v-for="(i, index) in item.plans" :key="i" eager>
                                <v-card flat class="ma-12">
                                    <v-layout wrap class="pa-0 ma-0">
                                        <v-flex xs12 class="text-right" pt-0>
                                            <v-btn small color="error" @click="removeGroup(index)" outlined>Deletar Grupo<v-icon right small> delete </v-icon></v-btn>
                                        </v-flex>
                                        <v-flex xs12>
                                            <v-text-field :label="$t('Título do grupo')" v-model="i.title" dense />
                                        </v-flex>
                                    </v-layout>
                                    <Plan v-if="i.items" :items="i.items" class="mt-12" />
                                </v-card>
                            </v-tab-item>
                        </v-tabs-items>
                    </v-card>
                </v-flex>
                <v-flex xs12>
                    <v-btn style="width: 100%" :loading="lock" :disabled="!valid" color="success" @click="validate">{{ $t("Salvar") }}</v-btn>
                </v-flex>
            </v-layout>
        </v-container>
    </v-form>
</template>

<script>
import { mask } from "vue-the-mask";
import rules from "@/helpers/rules";
import Plan from "./Plan";

const itemClean = {
    name: "",
    printName: "",
    price: null,
    enable: true,
    plans: [{ title: "", items: [{ title: "", reference: "", unit: "" }] }],
};

export default {
    components: { Plan },
    directives: { mask },

    props: {
        itemToEdit: {
            type: Object,
        },
        lock: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            rules,
            valid: true,
            tab: null,
            item: JSON.parse(JSON.stringify(itemClean)),
            needObservation: (value) =>
                (!!value && this.item.observationImportant) ||
                !this.item.observationImportant ||
                this.$t("Necessário colocar uma observação já que a mesma está marcada como importante."),
        };
    },

    watch: {
        itemToEdit: {
            immediate: true,
            handler(newVal) {
                if (this.$refs.form) {
                    this.$refs.form.reset();
                }

                this.$nextTick(() => {
                    if (newVal == null) {
                        this.item = JSON.parse(JSON.stringify(itemClean));
                    } else {
                        this.item = { ...newVal };

                        if (!this.item.plans || this.item.plans.length == 0) {
                            this.item.plans = [];
                        }
                    }
                });
            },
        },
    },

    methods: {
        removeGroup(index) {
            this.item.plans.splice(index, 1);
        },
        addGroup() {
            console.log(this.item.plans);
            this.item.plans.push({ title: "Nome do Grupo", items: [{ title: "", reference: "", unit: "" }] });
        },
        validate() {
            if (this.$refs.form.validate()) {
                this.$emit("save", this.item);
            }
        },
    },

    mounted() {
        if (this.$refs.form) {
            this.$refs.form.reset();
        }
    },
};
</script>